.rev_container{
    color:   rgba(80, 80, 80, 0.884); 
    margin-top: 120px;
    margin-inline:10% ;
}
.rev_box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}
.rev_box_1{
display: flex;
flex-direction: column;
width: 40%;
}
.rev_box_2{
margin-bottom: 10px;
width: 40%;
}
.rev_box_2 table tr td{
padding-inline: 30px;
padding-block: 10px;
}
.rev_box_2 table {
margin-top: 50px;
font-size: 14px;
}
.rev_box_2 h3{
text-align: right;
}
.vehicle_sec{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.438);
    border-radius: 10px;
}
.r_img_1{
    width: 140px;
    margin-bottom: 10px;
}
.rev_car_details{
    background: rgba(255, 0, 0, 0.055);
    padding: 10px;
    margin-block: 10px;
}
.rev_car_details h4{
    margin-bottom: 10px;
}
.v_r_1{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    
}
.rev_loc_details{
    background: rgba(255, 0, 0, 0.055);
    padding: 10px;
    margin-block: 10px;
}
.pick_rev{
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
}
.pro_sec{
    margin-block: 15px;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.438);
    border-radius: 10px;
}
.coupon_sec{
    margin-block: 15px;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.438);
    border-radius: 10px;
}
.coupon_sec p{
   cursor: pointer;
}
.pro_div_1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.total_sec{
    display: flex;
    justify-content: space-between;
    margin-block: 15px;
    padding: 10px;
    border: 2px solid rgba(255, 0, 0, 0.438);
    box-shadow: 0 0 5px #bf26266b;
    border-radius: 10px;
}
.total_sec_2{
    text-align: right;
}
.content-close-r {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    
  }
.content-open-r {
    opacity: 1;
    display: flex;
    padding: 1rem;
    height: auto;
    max-height: 9999px;
  }
.c_in{
    width: 200px;
    height: 35px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
.c_btn{
    padding-block: 5px ;
    padding-inline: 10px;
    color: white;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: rgba(255, 0, 0, 0.459)
    ;
}
@media only screen and (max-width: 480px) {
    .rev_container{
        margin-inline:5% ;
        font-size: 12px;
    }
    .vehicle_sec{
     flex-direction: column;
    }
    .rev_box_1{
        width: 100%;
        margin-bottom: 20px;
    }
    .c_in{
        width: 150px;
      }
    .c_btn{
            padding-block: 2px ;
            padding-inline: 10px;
            font-size: 10px;
    }
    .rev_box_2 table {
        margin-top: 0px;
        font-size: 12px;
        }
}