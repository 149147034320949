.mapContainer{
    text-align: center;
    margin-top: 60px;
    color: rgba(80, 80, 80, 0.884);
    font-family: 'Poppins', sans-serif;
    width: 100%;
}
.gMapHeader{
    margin-bottom: 30px;
    text-transform: uppercase;
}

@media only screen and (max-width: 480px) {
    .mapContainer{
        margin-top: 0px;
    }
  }