.footer_main {
  background: #ffffff;
}

.footer_d1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 70px 40px 0px 40px;
  font-size: 16px;
  color: rgba(80, 80, 80, 0.884);
}
.footer_d2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}
.f_m_header{
  margin-bottom: 15px;
}
.footer_middle{
  margin-top: 40px;
  font-size: 16px;
  display: flex;
  color: rgba(82, 78, 78, 0.575);
  justify-content: space-between;
  width: 100%;
}
.footer_m1_items{
  border: 0;
}

.about_d1 {
  width: 40%;
}
.FooterMenu{
  font-size: 16px;
list-style-type: none;
margin-bottom: 20px;
}
.lnk-menu-footer{
  display: block;
  padding: 3px;
  color: rgba(80, 80, 80, 0.884);
  text-decoration: none;
}
.lnk-menu-footer:hover {
  color: rgba(211, 17, 17, 0.521);
}

.about_log_d {
  padding: 20px 0;
}

.about_log {
  width: 138px;
}

.about_p1 {
  color: rgba(80, 80, 80, 0.884);
  font-size: 16px;
}

.footer_icon {
  color: rgba(80, 80, 80, 0.884);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin: 5px;
  font-size: 18px;
}

.icon_container{
  display: flex;
  justify-content: center;
}

.icons{
  width: 20px;
  margin-inline: 8px;
}

.social_icon {
  color: rgb(100, 152, 212);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin: 5px;
  font-size: 18px;
}

.social_title {
  text-decoration: none;
  color: rgba(80, 80, 80, 0.884);;
  
}

.footer_icon:hover,
.touch_icon:hover {
  color: rgba(211, 17, 17, 0.521);
  box-shadow: 1px 5px 5px 2px rgba(206, 246, 255, 0.481);
}

.FLink {
  list-style: none;
}
.footer_Li {
  margin: 5px 0px;
}
.footer-links {
  text-decoration: none;
  color: rgba(80, 80, 80, 0.884);;
  font-size: 16px;
}

.touch_a:hover,
.touch_tnc:hover,
.footer-links:hover {
  color: rgba(211, 17, 17, 0.521);
  border-radius: 10px;
  text-shadow: 2px 5px 10px #2c92d649;

}

.touch_d1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.connected_cl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.touch_icon {
  color: rgba(80, 80, 80, 0.884);;
  font-size: 16px;
}
.touch_a {
  text-decoration: none;
  color: rgba(80, 80, 80, 0.884);;
  margin-left: 5px;
  font-size: 16px;
}
.touch_tnc {
  text-decoration: none;
  color: rgba(80, 80, 80, 0.884);;
  margin-left:25px;
  font-size: 13px;
}

.bottom_f_d1 {
  background: #f0f4fb;
  padding: 20px;
  width: 100%;
  border: solid 1px rgb(255, 255, 255);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 70px;
}
.bottom_1{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom_2{
  font-size: 13px;
  text-align: center;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 2px solid gray;
}

.copyright {
  color: rgba(80, 80, 80, 0.884);
  font-size: 12px;
}

@media only screen and (max-width: 617px) {
  .footer_d1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 30px 0px 30px;
  }
  
  .about_p1{
  margin-bottom: 20px;
  }
  .footer_d2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .about_d1 {
    width: 100%;
  }
 
  .company_d1 {
    padding: 40px 0px;
  }
  .footer_middle{
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
  }
  .footer_m1_items{
    margin-top: 15px;
  }
  .bottom_f_d1 {
    background: #f0f4fb;
    padding-block: 20px;
    padding-inline:10px ;
    width: 100%;
    border: solid 1px rgb(255, 255, 255);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 50px;
  }
  .bottom_1{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .copyright {
    color: rgba(80, 80, 80, 0.884);;
    font-size: 12px;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .footericon_d1 {
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .touch_tnc {
    margin-inline:5px;
    font-size: 12px;
   
  }
  .icon_container{
    margin-block: 10px;
  }
}
