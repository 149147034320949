.city_Con {
    color: rgba(80, 80, 80, 0.884);
}
.cityHead {
    margin: 30px;
    text-align: center;
}

.city_grid {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.city_Card {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(128, 128, 128, 0.281);
}
.city-name {
    width: 200px;
}
.city_img {
    height: 200px;
    width: 300px;
}
.city_dec {
    margin-block: 10px;
}
.city_btn {
    display: flex;
    justify-content: space-between;
}
.city_btn_1 {
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    border: none;
    background-image: linear-gradient(
        to right,
        #ff745d 0%,
        #e26082 51%,
        #af5e96 100%
    );
    color: white;
}
.city_btn_2 {
    font-size: 16px;
    cursor: pointer;
    padding: 15px;
    border: none;
    background-color: rgba(0, 255, 0, 0.616);
    color: white;
}
