.book_con {
    cursor: pointer;
    padding: 10px;
    border: 1px solid rgba(255, 0, 0, 0.089);
    background: white;
    color: rgba(80, 80, 80, 0.884);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.book_con:hover {
    box-shadow: 1px 1px 20px 10px rgba(247, 122, 122, 0.486);
    transition: all 0.4s ease-in-out;
}
.book_con_2 {
    padding-inline: 20px;
}
.book_con_3 {
    padding-block: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.151);
}
.book_con_3_in {
    margin-top: 5px;
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
.book_con_4 {
    display: flex;
    margin-block: 5px;
    justify-content: space-between;
}
.btn_book_ad {
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    border: none;
    background-image: linear-gradient(
        to right,
        #ff745d 0%,
        #e26082 51%,
        #af5e96 100%
    );
    color: white;
}
