.userbook-con {
    margin: 8% 5%;
}
.btn_book_ad_user {
    width: 100%;
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    border: none;
    background-image: linear-gradient(
        to right,
        #ff745d 0%,
        #e26082 51%,
        #af5e96 100%
    );
    color: white;
}
.btn_book_ad_user:hover {
    background-image: linear-gradient(
        to right,
        #f86248 0%,
        #e74973 51%,
        #b44d94 100%
    );
}
.popup_p-c-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}
