.book-car-con {
    margin-top: 40px;
    padding-block: 5%;
    padding-inline: 10%;
    color: rgba(80, 80, 80, 0.884);
    background: #fc5f5f;
}
.bc_main {
    color: rgba(80, 80, 80, 0.884);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
}
.bc_h1 {
    margin-bottom: 14px;
    text-transform: uppercase;
}
.bc_p1 {
    font-size: 16px;
}
.introTitleRed {
    color: rgba(255, 0, 0, 0.658);
}
.greenHead {
    color: rgba(0, 128, 0, 0.726);
}
.introTitleBlack {
    color: rgba(0, 0, 0, 0.829);
    font-weight: 600;
}

.bc_d1 {
    padding: 40px 20px 20px 20px;
    color: rgba(80, 80, 80, 0.884);
}
.bc_h2 {
    font-size: 100px;
    color: white;
    margin-bottom: 20px;
}
.bc_d2 {
    padding: 20px 0px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.bc_d3 {
    background: rgba(255, 252, 252, 0.822);
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-inline: 20px;
    margin-right: 50px;
    width: 40%;
    display: flex;
    flex-direction: column;
    cursor: default;
}

.bc_img_d1 {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 5px;
    display: flex;
}

.bc_img1 {
    width: 100%;
    border-radius: 10px;
    align-self: center;
    transition: 0.5s all ease-in-out;
}
/* .bc_img_d1:hover .bc_img1 {
    transform: scale(1.2);
} */
.bc_map_main {
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: "Poppins", sans-serif;
}
.bc_d4 {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin: 10px 5px;
    color: rgba(80, 80, 80, 0.884);
}
.bc_d5 {
    border: 2px dashed #ff745d;
    border-radius: 12px;
    padding: 2px 10px;
}
.bc_icon {
    color: #ff889e;
    margin-right: 10px;
    font-size: 13px;
    align-self: center;
}
.bc_span1 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 13px;
}

.bc_d7 {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 5px 0px;
    color: rgba(80, 80, 80, 0.884);
}
.bc_d8 {
    color: rgba(80, 80, 80, 0.884);
}

.bc_d10 {
    border-top: solid rgb(214, 214, 214) 0.1px;
    padding: 5px 10px;
    margin-top: 10px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: rgba(80, 80, 80, 0.884);
}

.bc_span2 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 20px;
}

.bc_span3 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 14px;
}

.options {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin-bottom: 40px;
}
.btn-book-unlimited:disabled {
    background-color: #a4a4a4;
    cursor: not-allowed;
}
.option-box {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid white;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 20px;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.1);
}
.option-box-1 {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid white;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 20px;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.1);
}

.inner-option-1 {
    display: flex;
    justify-content: space-around;
}
.inner-option-2 {
    display: flex;
    justify-content: space-around;
}
.inner-option-3 {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.duration-gap-book {
    font-size: 25px;
}
.duration-div-book {
    display: flex;
    align-items: center;
}
.option-box-2 {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid white;
    padding: 10px;
    font-size: 14px;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.1);
}
.option-box-2-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.option-box-2-inner-p {
    margin-inline: 10px;
}

.accordion-book-content-close {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0, 1, 0, 1);
}
.accordion-book-content-open {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    opacity: 1;
    padding: 10px;
    height: auto;
    max-height: 9999px;
    transition: all 0.3s cubic-bezier(1, 0, 1, 0);
}
.book-input-location {
    padding: 5px;
    width: 100%;
    height: 70px;
    border-radius: 5px;
}
.book-input-location-checkbox {
    display: flex;
    align-items: center;
    margin-block: 30px;
}
.book-input-location-checkbox-inner {
    margin-left: 10px;
    height: 30px;
    width: 30px;
}
.book-proceed2-button {
    color: white;
    display: flex;
    background: rgba(255, 0, 0, 0.603);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border: 1px solid rgba(255, 0, 0, 0);
    cursor: pointer;
}
.book-proceed2-buttonShadow {
    box-shadow: 0px 0px 7px rgba(255, 0, 0, 0.468);
}
.high-text-book {
    color: rgba(255, 0, 0, 0.747);
}
.book-box-4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn-book-unlimited {
    box-shadow: 0px 0px 7px rgba(255, 0, 0, 0.468);
    color: white;
    background: rgba(255, 0, 0, 0.603);
    cursor: pointer;
    width: 150px;
    padding: 3px;
    margin-left: 20px;
    border-radius: 5px;
    border: 1px solid rgba(255, 0, 0, 0);
}
.btn-book-unlimited-dis {
    box-shadow: 0px 0px 7px rgba(136, 136, 136, 0.468);
    color: white;
    background: rgba(136, 136, 136, 0.468);
    cursor: pointer;
    width: 150px;
    padding: 3px;
    margin-left: 20px;
    border-radius: 5px;
    border: 1px solid rgba(255, 0, 0, 0);
}
.box-5-book {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.box-5-book-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-5-book-inner-11 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}
.box-5-book-inner-22 {
    border-top: 1px solid rgba(0, 0, 0, 0.301);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}
.box-5-book-inner-33 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.301);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 15px;
}
.box-5-book-inner-33 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.301);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 15px;
}
.box-5-book-inner-66 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.301);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 15px;
}
.box-5-book-inner-44 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 15px;
}
.box-5-book-inner-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.book-input-promo {
    padding: 5px;
    width: 100%;
    border-radius: 5px;
}
.book-last-box-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.book-input-location-checkbox-inner-last {
    height: 30px;
    width: 30px;
    margin-right: 15px;
}
.box-5-book-inner-drop {
    display: flex;
}
.checkbox-container {
    display: flex;
    margin-top: 10px;
}
.green-title-head {
    color: rgba(16, 129, 16, 0.863);
}
.btn-add-promo-item {
    box-shadow: 0px 0px 7px rgba(255, 0, 0, 0.468);
    color: white;
    background: rgba(255, 0, 0, 0.603);
    cursor: pointer;
    width: 50px;
    padding: 3px;
    margin-left: 20px;
    border-radius: 5px;
    border: 1px solid rgba(255, 0, 0, 0);
}
.inner-promocode-show {
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.637);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}
.proceed-book-btn {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.checkbox-label {
    margin-left: 10px;
}
.side-back-book {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.amount {
    margin-top: 20px;
    font-weight: bold;
}

.inner-promocode-show {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* pop up screen */
.popup-screen-book {
    color: rgba(80, 80, 80, 0.884);
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-book {
    color: rgba(80, 80, 80, 0.884);
    background-color: white;
    border: 1px solid rgba(255, 0, 0, 0.514);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px #af0606 51%;
}
.popup_p-book {
    margin: 10px;
}
.popup_p-book-2 {
    display: flex;
    color: rgba(0, 128, 0, 0.678);
    justify-content: center;
    margin-bottom: 10px;
}
.high-text-book-2 {
    padding-block: 5px;
    padding-inline: 43%;
    border-radius: 30px;
    border: 1px solid rgba(0, 128, 0, 0.678);
}
.popup_b-book {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}
.popup-ul-book {
    margin: 20px;
}

@media only screen and (max-width: 480px) {
    .book-car-con {
        margin-top: 40px;
        padding-block: 5%;
        padding-inline: 5%;
    }
    .bc_map_main {
        flex-direction: column-reverse;
    }
    .bc_d3 {
        width: 100%;
    }
    .bc_img_d1 {
        width: 100%;
    }
    .bc_h2 {
        text-align: center;
        font-size: 50px;
        color: white;
        margin-bottom: 10px;
    }
    .duration-gap-book {
        font-size: 10px;
    }
    .bc_d7 {
        justify-content: space-around;
    }
    .spec-book-car {
        visibility: hidden;
        max-width: 0px;
        max-height: 0px;
    }
}
