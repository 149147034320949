* {
    font-family: "Poppins" !important;
}

.blogcard {
    width: 100%;
}
.cardAnk {
    font-family: "Poppins", sans-serif !important;
    text-decoration: none;
    width: 300px;
    cursor: pointer;
    box-shadow: 12px 12px 2px 1px rgba(55, 55, 56, 0.137);
}
.cardAnk:hover {
    transform: translateY(-5px);
    background: rgba(255, 0, 0, 0.089);
}
.description-blog {
    font-family: "Poppins", sans-serif !important;
    height: 140px;
    color: rgba(80, 80, 80, 0.884) !important;
}
.blogImage {
    height: 200px;
}
@media only screen and (max-width: 617px) {
    .cardAnk {
        width: 270px;
    }
}
