.faqContainer{
    margin-top: 120px;
    margin-inline: 15% ;
    color: rgba(80, 80, 80, 0.884);
}
.faqsTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.faqItems{
    margin-top: 25px;
    font-size: 16px;
}

@media only screen and (max-width: 617px) {
    .faqContainer{
        margin-top: 100px;
        margin-inline: 5% ;
    }
}