.d_btn_bck_con {
    display: flex;
}
.d_btn_bck {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    border: none;
    margin-block: 50px;
    background: rgba(255, 0, 0, 0.521);
}
