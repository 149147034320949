.con_main {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.con_h_d1 {
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.con_h_h1 {
  color: rgba(80, 80, 80, 0.884);
  /* font-size: calc(22px + (7-25) * (100vw - 320px) / (1920-320)); */
  text-transform: uppercase;
}

.con_de_d1 {
  padding: 0 0 60px 0;
  box-sizing: border-box;
}
.con_de_d2 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
}

.con_box {
  color: rgba(80, 80, 80, 0.884);
  background: white;
  margin: 0 2%;
  padding: 15px 25px;
  flex-direction: column;
  justify-content: center;
  box-shadow: 10px 10px 10px 10px rgba(206, 246, 255, 0.2);
  border-radius: 10px;
  cursor: pointer;
}

.con_box:hover {
  box-shadow: 1px 1px 20px 10px rgba(135, 209, 255, 0.785);
  transition: all 0.4s ease-in-out;
}

.con_box_d1 {
 
  padding: 10px;
  border-radius: 10px;
  background: #f6d7e7;
  width: fit-content;
  margin-bottom: 10px;
  font-size: 16px;
  color: #d23787;
  /* font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320)); */
}
.con_box_d2 {

  padding: 10px;
  border-radius: 10px;
  background: #d1ebfd;
  width: fit-content;
  margin-bottom: 10px;
  color: #189cf4;
  /* font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320)); */
}
.con_box_d3 {
  padding: 10px;
  border-radius: 10px;
  background: #d4f0e3;
  width: fit-content;
  margin-bottom: 10px;
  color: #29b474;
  /* font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320)); */
}
.con_box_d4 {
  
  padding: 10px;
  border-radius: 10px;
  background: #e6dcf6;
  width: fit-content;
  margin-bottom: 10px;
  color: #8051d4;
  /* font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320)); */
}

@media only screen and (max-width: 651px) {
  .con_de_d2 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
  }
  .con_box {
    background: white;
    margin: 2% 2%;
    padding: 15px 25px;
    flex-direction: column;
    justify-content: center;
    box-shadow: 10px 10px 10px 10px rgba(206, 246, 255, 0.2);
    border-radius: 10px;
  }
}
