.adBLogContainer{
    margin-top: 70px;
    margin-inline: 10%;
    padding: 3%;
    color: rgba(80, 80, 80, 0.884);
    border: 1px;
    background-color: rgba(128, 128, 128, 0.119);
}
.blogFormHeadings{
    color: rgba(80, 80, 80, 0.884);
}
.urlImport {
    color: transparent;
  }
  .urlImport::-webkit-file-upload-button {
    visibility: hidden;
  }
  .urlImport::before {
    content: 'Browse Picture';
    color: rgba(80, 80, 80, 1);
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .urlImport:hover::before {
    border-color: black;
  }
  .urlImport:active {
    outline: 0;
  }
  .urlImport:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
  }
  