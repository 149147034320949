.art_h_contain{
    margin-inline: 10%;
    margin-block: 50px;
    font-size: 16px;
    color: rgba(80, 80, 80, 0.884);
}
.art_h_contain h2{
    margin-block: 20px;
    text-align: center;
}
