.ax_c {
    background-color: white;
    color: rgba(80, 80, 80, 0.884);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
    margin-block: 20px;
    height: 380px;
    cursor: pointer;
}
.ax_c:hover {
    transform: translateY(-5px);
    background: rgba(255, 0, 0, 0.089);
}
.ax_cars {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
