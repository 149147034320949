.Ccontainer{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 120px;
    color: rgba(80, 80, 80, 0.884);
    font-size: 16px;
}
.Cheader{
    display: flex;
    margin-bottom: 15px;
    color: rgba(80, 80, 80, 0.884);
    justify-content: center;
}
.Clists{
    margin-left: 5%;
}
.ClistsHead{
    margin-bottom: 7px;
    font-weight: 600;
}