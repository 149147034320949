.noti_Con {
    color: rgba(80, 80, 80, 0.884);
}
.notiHead {
    margin: 30px;
    text-align: center;
}
.not-card-con {
    margin-block: 50px;
}
.not-card {
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.379);
    padding: 10px;
    align-items: center;
    background: rgba(255, 0, 0, 0.076);
}
.not-card-icon {
    width: 50px;
    height: 50px;
    margin-right: 60px;
    color: rgba(0, 128, 0, 0.718);
}
.not-card-icon-2 {
    width: 50px;
    height: 50px;
    margin-right: 60px;
    color: rgba(128, 0, 0, 0.718);
}
.not-card-des {
    width: 100%;
}
.not-card-headers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.not-card-header {
    font-weight: bolder;
    font-size: 20px;
}
.not-card-text {
    margin-top: 5px;
}
