.whyContainer {
    color: rgba(80, 80, 80, 0.884);
    display: flex;
    margin-inline: 10%;
    align-items: center;
    margin-top: 60px;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
}
.whyGwtHeader {
    margin-bottom: 15px;
    text-transform: uppercase;
}
.whyGwtText {
    width: 100%;
    margin-right: 30px;
    font-size: 16px;
}
.whyGwtTextUl {
    padding-left: 2%;
}
.redHead {
    color: rgba(255, 0, 0, 0.704);
}
.whyAnimation {
    width: 100%;
}
@media only screen and (max-width: 480px) {
    .whyContainer {
        flex-direction: column;
    }
}
