.ex_main {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  background: rgb(237, 241, 247);
}

.ex_container {
  padding: 140px 9% 140px 9%;
}
.ex_h_h1 {
  color: rgba(80, 80, 80, 0.884);
}

.ex_h_d1 {
  padding: 0 0 40px 0;
  box-sizing: border-box;
}

.ex_se_d1 {
  padding: 0px 0px 40px 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ex_se_d2 {
  background: rgb(240, 244, 251);
  border: solid white 0.5px;
  border-radius: 20px;
  padding: 20px;
  width: 97%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: rgba(46, 46, 46, 0.041) 1px 1px 3px 2px;
}
.ex_se_d3 {
  display: flex;
  width: 50%;
}

.ex_se_in {
  display: flex;
  width: 100%;
  padding: 7px 10px;
  border: 0.1px solid rgb(223, 223, 223);
  transition: all 0.35s ease-in-out;
  border-radius: 5px;
}

.ex_se_in:focus,
.ex_fil_se:focus {
  outline: none !important;
  border: 0.1px solid rgb(30, 203, 255);
}

.ex_fil_d1 {
  display: flex;
  width: 50%;
  margin-left: 10px;
}

.ex_fil_se {
  display: flex;
  padding: 7px 10px;
  border: 0.1px solid rgb(223, 223, 223);
  transition: all 0.35s ease-in-out;
  border-radius: 10px;
  background: rgb(240, 244, 251);
}

/* .ex_btn1{
    width: 100%;
    padding: 10px;
    background: white;
    border: 0.1px solid rgb(223, 223, 223);
    cursor: pointer;
    border-radius: 10px;
    text-transform: uppercase;
    color: rgb(50, 50, 50);
    transition: all .35s ease-in-out;
}
.ex_btn1:hover{
    border: 0.1px solid rgb(30, 203, 255);
} */

.ex_btn1 {
  width: 100%;
  padding: 10px;
  background: white;
  border: 0.1px solid rgb(223, 223, 223);
  cursor: pointer;
  border-radius: 10px;
  text-transform: uppercase;
  position: relative;
}

.ex_btn1:hover {
  border: 0.1px solid rgb(30, 203, 255);
}
.ex_btn1::before {
  transition: all 0.85s;
  content: "";
  width: 0%;
  height: 100%;
  background: rgb(30, 203, 255);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.ex_btn1 .ex_btn1-text {
  color: white;
  mix-blend-mode: difference;
}
.ex_btn1:hover::before {
  background: rgb(255, 255, 255);
  width: 100%;
  border-radius: 10px;
}

.ex_btn2-text2 {
  color: rgba(80, 80, 80, 0.884);
  cursor: pointer;
  font-size: 13px;
  text-align: center;
}
