
.contactLinkContainer{
  margin-left: 8%;
  margin-top: 6%;
  display: flex;
  justify-content: space-between;
}
.contact_subHeader{
  margin-top: 5px;
  font-size: 14px;
}
.CInfoContainer{
  margin-bottom: 50px;
  color: rgba(80, 80, 80, 0.884);
}
.CInfo{
  display: flex;
  margin-bottom: 17px;
}
.CInfoText{
  margin-left: 15px;
}
.redContactIcons{
  color: rgba(255, 0, 0, 0.596);
}
.contactArrow{
 padding-inline: 10px;
}
.contactAnk{
  text-decoration: none;
  color: rgba(80, 80, 80, 0.884);
}
.socialContacts{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 0px;
  border-bottom: .5px solid gray;
}
.Cconnected_cl{
  display: flex;
  align-items: center;
  margin-inline: 5px;
}
.Cicons{
  width: 30px;
}
.contactForm{
  margin-left: 100px;
  color: rgba(80, 80, 80, 0.884);
}
@media only screen and (max-width: 1300px) and (min-width: 961px) {
  .contactLinkContainer{
    margin-inline: 5%;
    margin-top: 100px;
    margin-bottom: 50px;
    flex-direction: column;
  }
  .contactForm{
    margin-left: 0px;
    color: rgba(80, 80, 80, 0.884);
  }
  
}
@media only screen and (max-width: 960px) {

  .contactLinkContainer{
    margin-inline: 5%;
    margin-top: 100px;
    margin-bottom: 50px;
    flex-direction: column;
  }
  .contactForm{
    margin-left: 0px;
    color: rgba(80, 80, 80, 0.884);
  }

}