.home_container {
    background: rgb(237, 241, 247);
    padding: 0px 140px;
    justify-content: center;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1200px) and (min-width: 1025px) {
    .home_container {
        background: rgb(237, 241, 247);
        padding: 0px 70px;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
}

/* ================================================== mobile=============================================================== */
@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .home_container {
        background: rgb(237, 241, 247);
        display: flex;
        padding-inline: 70px;
        flex-direction: column;
        justify-content: center;
    }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
    .home_container {
        background: rgb(237, 241, 247);
        padding-inline: 70px;
        justify-content: center;

        display: flex;
        flex-direction: column;
    }
}
@media only screen and (max-width: 480px) {
    .home_container {
        background: rgb(237, 241, 247);
        padding: 0px 10px;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
}
