/* cst.css */
.customer-container {
    cursor: pointer;
    width: 300px;
    margin: 10px;
    border-radius: 12px;
    padding: 10px;
    background-color: white;
    color: rgba(80, 80, 80, 0.884);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.customer-container:hover {
    transform: translateY(-5px);
    background: rgba(255, 0, 0, 0.089);
}

.customer-info {
    display: flex;
    margin: 15px 5px;
    align-items: center;
}

.customer-image {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.customer-details {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.customer-active {
    padding: 10px;
    border-radius: 10px;
    background: rgba(255, 0, 0, 0.062);
    color: rgba(0, 128, 0, 0.664);
}

.customer-earnings {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

/* Add more CSS styles as needed */
