.contactUsForms{
    width: 1000px;
}
.C_f_name{
    display: flex;
    
}
.contact_subHeader{
    margin-top: 5px;
    font-size: 14px;
}
.smallForm{
   width: 400px;
}
.smallFormRight{
   width: 400px;
}
.c_TextInput{
    width: 100%;
}

@media only screen and (max-width: 1500px)and (min-width: 1301px)  {
    .contactUsForms{
        width: 800px;
    }
    .smallForm{
        width: 320px;
     }
     .c_TextInput{
        width: 100%;
    }
    .smallFormRight{
        width: 320px;
     }
    
  }
@media only screen and (max-width: 480px) {
    .contactUsForms{
        width: 100%;
    }
    .C_f_name{
        flex-direction: column;
    }
    .smallForm{
        width: 100%;
     }
     .c_TextInput{
        width: 100%;
    }
    .smallFormRight{
        width: 100%;
     }
    
  }