.ad_book_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ad_book_5 {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}
.ad_book_2 {
    margin-bottom: 50px;
}
.ad_book_3 {
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
    padding-inline: 40px;
    padding-block: 18px;
    font-size: 18px;
    margin-inline: 10px;
    cursor: pointer;
    background: rgba(228, 7, 7, 0.24);
    text-decoration: none;
    align-items: center;
}
.ad_book_3_active {
    text-decoration: none;
    color: white;
    padding-inline: 40px;
    padding-block: 18px;
    font-size: 18px;
    margin-inline: 10px;
    cursor: pointer;
    background: rgba(228, 7, 7, 0.534);
    text-decoration: none;
    align-items: center;
}
