.ad_comp_1_t {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.ad_comp_1_src_c {
    display: flex;
    justify-content: center;
    width: 100%;
}
.ad_comp_1_src_c_2 {
    display: flex;
    width: 800px;
    margin-bottom: 50px;
    border: 1px solid rgba(255, 0, 0, 0.274);
    background: white;
    border-radius: 15px;
    padding: 10px 10px;
    align-items: center;
}
.ad_comp_1_src {
    border: none;
    width: 800px;
    align-items: center;
    margin-left: 0px;
    text-align: center;
}
.ad_comp_1_src:focus {
    outline: none; /* Remove the default focus outline */
    border-color: transparent;
}
.btn_host_cus_search {
    border: none;
    background: rgba(255, 0, 0, 0.315);
    padding: 3px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    color: rgba(80, 80, 80, 0.884);
}
.ad_comp_1_cards_c {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 20px;
}
.ad_comp_1_cards_c div {
    cursor: pointer;
}
.ad_comp_1_cards_c_lnk {
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
}

.dashboardHead {
    margin: 30px;
    text-align: center;
}
