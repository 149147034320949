/* took hrlp from car card */
.ad_con_c {
    padding-bottom: 150px;
}
.ad_p_c_container {
    position: relative;
    padding-top: 82px;
    display: flex;
    background-color: #f8f9fa;
    color: rgba(80, 80, 80, 0.884);
    padding-bottom: 50px;
}
.ad_c_comp_3 {
    width: 300px;
}
.carlistHead {
    margin: 30px;
    text-align: center;
}
.adc_main {
    background: rgb(237, 241, 247);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
}
.moreButton {
    text-align: center;
    margin-block: 30px;
}
.moreButtonIn {
    text-align: center;
    height: 40px;
    width: 200px;
}
.adc_h1 {
    margin-bottom: 14px;
    text-transform: uppercase;
}
.adc_p1 {
    font-size: 16px;
}
.introTitleRed {
    color: rgba(255, 0, 0, 0.658);
}

.adc_d1 {
    padding: 40px 20px 20px 20px;
    color: rgba(80, 80, 80, 0.884);
}

.adc_d2 {
    padding: 20px 0px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.adc_d3 {
    padding: 10px;
    border: solid white 0.1px;
    border-radius: 10px;
    width: 100%;
    background: rgb(241, 246, 253);
    flex-direction: column;
    box-shadow: rgba(46, 46, 46, 0.041) 1px 1px 3px 2px;
    cursor: default;
}
.adc_d3:hover {
    transform: translateY(-5px);
    background: rgba(255, 0, 0, 0.089);
}

.adc_img_d1 {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
}

.adc_img1 {
    width: 100%;
    border-radius: 10px;
    align-self: center;
    transition: 0.5s all ease-in-out;
}
.adc_img_d1:hover .adc_img1 {
    transform: scale(1.2);
}
.adc_map_main {
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: "Poppins", sans-serif;
}
.adc_d4 {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin: 10px 5px;
    color: rgba(80, 80, 80, 0.884);
}
.adc_d5 {
    border: 2px dashed #ff745d;
    border-radius: 12px;
    padding: 2px 10px;
}
.adc_icon {
    color: #ff889e;
    margin-right: 10px;
    font-size: 13px;
    align-self: center;
}
.adc_span1 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 13px;
}

.adc_d7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 5px 0px;
    color: rgba(80, 80, 80, 0.884);
}
.adc_d8 {
    width: 40%;
    color: rgba(80, 80, 80, 0.884);
}

.adc_d10 {
    border-top: solid rgb(214, 214, 214) 0.1px;
    padding: 5px 10px;
    margin-top: 10px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: rgba(80, 80, 80, 0.884);
}

.adc_span2 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 20px;
}

.adc_span3 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 14px;
}

.cars-btn-ad {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.adc_btn1 {
    width: 100%;
    font-family: "Poppins", sans-serif;
    background-image: linear-gradient(
        to right,
        #ff745d 0%,
        #e26082 51%,
        #af5e96 100%
    );
    margin: 10px;
    padding: 15px 13px 12.5px 13px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    font-size: 13px;
    border-radius: 10px;
    display: block;
    cursor: pointer;
    border: none;
}

.adc_btn1:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
/* pop up screen */
.popup-screen {
    color: rgba(80, 80, 80, 0.884);
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup {
    color: rgba(80, 80, 80, 0.884);
    background-color: white;
    border: 1px solid rgba(255, 0, 0, 0.514);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px #af0606 51%;
}
.popup_p {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}
.popup_b {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}
.popup_b a {
    text-decoration: none;
}
.popup_b a button {
    font-family: "Poppins", sans-serif;
    background-image: linear-gradient(
        to right,
        #ff745d 0%,
        #e26082 51%,
        #af5e96 100%
    );
    margin: 10px;
    padding: 15px 13px 12.5px 13px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    font-size: 13px;
    border-radius: 10px;
    display: block;
    cursor: pointer;
    border: none;
}
.popup_b a button:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
@media only screen and (max-width: 850px) and (min-width: 481px) {
    .adc_d1 {
        padding-top: 10%;
        text-align: center;
    }
}
@media only screen and (max-width: 480px) {
    .adc_d1 {
        padding-top: 25%;
        text-align: center;
    }
}
