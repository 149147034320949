.blogsContainer {
    margin-top: 120px;
    margin-inline: 10%;
    color: rgba(80, 80, 80, 0.884);
    font-family: "Poppins" !important;
}
.shimmerCard {
    display: flex;
    flex-direction: column;
}
.blogCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
    row-gap: 50px;
    column-gap: 5%;
    font-family: "Poppins" !important;
}
@media only screen and (max-width: 617px) {
    .blogCards {
        grid-template-columns: repeat(1, 1fr);
    }
    .blogsContainer {
        margin-left: 18%;
    }
}
