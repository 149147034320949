.wind_contain{
    color: rgba(80, 80, 80, 0.884);
    padding: 15px;
    border: 1px solid rgba(128, 128, 128, 0.411);
    border-radius: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 230px;
    margin-right: 30px;
}
.win_btn{
    width: 200px;
    margin-top: 15px;
    padding-inline: 20px;
    padding-block: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgba(231, 47, 47, 0.527);
    color: white;
}
.wind_contain p{
    font-size: 14px;
    width: 200px;
}
@media only screen and (max-width: 480px) {
    .wind_contain{
        margin-right: 0px;
        margin-bottom: 10px;
    }
}