.s_c_Container {
    margin-block: 50px;
    margin-inline: 10%;
}
@media only screen and (max-width: 480px) {
    .s_c_Container {
        margin-inline: 5%;
        margin-block: 50px;
    }
}
