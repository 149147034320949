.two-boxes {
    display: flex;
    margin-bottom: 20px;
    margin-top: 5px;
    width: 100%;
}

.pixel-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 60px;
    width: 100%;
    background-color: #f5e3e3;
    cursor: pointer;
    padding: 10px;
    font-weight: lighter;
}
.tra_act {
    border-left: 1px solid rgba(128, 128, 128, 0.356);
}
.driver_act {
    border-left: 1px solid rgba(128, 128, 128, 0.356);
}
.box.active {
    background-color: rgba(241, 47, 47, 0.781);
    color: white;
}
@media only screen and (max-width: 480px) {
    .box-show {
        visibility: hidden;
        max-width: 0;
    }

    .box {
        height: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        padding-inline: 2px;
        width: 100px;
        text-align: center;
    }
    .pixel-image {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}
