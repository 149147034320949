/* User.css */
.user-add-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-add-info {
    font-size: 16px;
    margin-bottom: 10px;
}

.user-add-image {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
}

.user-add-not-found {
    font-size: 18px;
    text-align: center;
    color: #f00;
}
