.In_c_c {
    width: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-block: 10px;
    border: 1px solid white;
    padding-inline: 20px;
    padding-block: 5px;
    justify-content: space-between;
    background: white;
}
.In_c_c_Info {
    display: flex;
    flex-direction: column;
}
.In_c_c_Info_2 {
    display: flex;
    justify-content: space-between;
}
.In_c_c_Info_3 {
    margin-right: 25px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}
.In_c_pic {
    height: 80px;
    width: 100px;
    margin-inline: 30px;
    font-size: 9px;
}
