/* account.css */

/* Main container */
.acc-main {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* Popup screen */
.popup-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

/* Popup */
.popup {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Popup content */
.popup-p {
    text-align: center;
    margin-bottom: 20px;
}

/* Popup buttons */
.popup-b {
    text-align: center;
}

/* User data container */
.user-data-acc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Account details section */
.user-data-acc h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Input fields */
.acc-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.acc-image {
    width: 100px;
}
/* Save changes button */
.car-dtls-btns button {
    background-color: #f84848cb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.car-dtls-btns button:hover {
    background-color: #a10c0ccb;
}
