.accordion-item {
    margin-bottom: 15px;
    color: rgba(80, 80, 80, 0.884);
}

.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 1rem;
    background-color: rgba(128, 128, 128, 0.185);
}

.accordion-title:hover {
    background-color: rgba(219, 44, 44, 0.201);
}

.accordion-content-close {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
}
.accordion-content-open {
    opacity: 1;
    background-color: rgba(128, 128, 128, 0.095);
    padding: 1rem;
    height: auto;
    max-height: 9999px;
}
