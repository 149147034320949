.lease-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-inline: 5%;
    color: rgba(80, 80, 80, 0.884);
}
.lease-table {
    display: flex;
    justify-content: center;
}
