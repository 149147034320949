.ic_Container{
    background: rgba(255, 0, 0, 0.329);
    width: 100%;
    height: 100%;
    padding-block: 50px;
    display: flex;
    flex-direction: column;
    color: rgba(80, 80, 80, 0.884);
}
.icons_c1{
    padding-inline: 50px;
    cursor: pointer;
    display: flex;
    color: rgba(80, 80, 80, 0.884);
    text-decoration: none;
    align-items: center;
    margin-top: 20px;
}
.icons_c1_Active{
    padding-inline: 50px;
    cursor: pointer;
    border-left:5px solid rgba(255, 255, 255, 0.808) ;
    display: flex;
    color: white;
    background: rgba(228, 7, 7, 0.24);
    text-decoration: none;
    align-items: center;
    margin-top: 20px;
}
.icon_ap{
    margin-right: 10px;
    width: 20px;
    height: 40px;
}