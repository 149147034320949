.modal_container{
    border: 1px solid red;
    height: 339px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
}
.modal_buttons{
    display: flex;
    align-items: center ;
    margin-top: 30px;
    justify-content: space-around 
}
.btn{
    margin-top: 5px;
    width: 160px;
}

@media only screen and (max-width: 600px)  and (min-width: 371px){
    .modal_container{
        height: 398px;
    }
    .modal_buttons{
        display: flex;
        flex-direction: column;
        align-items: center ;
        margin-top: 30px;
        justify-content: space-around ;
    }
  }
@media only screen and (max-width: 370px) 
{
    .modal_container{
        height: 420px;
    }
    .modal_buttons{
        display: flex;
        flex-direction: column;
        align-items: center ;
        margin-top: 30px;
        justify-content: space-around ;
    }
  }
