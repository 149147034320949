.point_main {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  .point_h_d1 {
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .point_h_h1 {
    color: rgba(80, 80, 80, 0.884);
   text-align: center;
  }
  .introTitleRed{
    color: rgba(255, 0, 0, 0.658);
  }
  .point_de_d1 {
    padding: 0 0 60px 0;
    box-sizing: border-box;
  }
  .point_de_d2 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    font-size: 16px;
    color: rgba(80, 80, 80, 0.884);
  }
  
  .point_box {
    background: white;
    margin: 0.5% 1%;
    padding: 10px 20px;
    flex-direction: row;
    justify-content: center;
    box-shadow: 10px 10px 10px 10px rgba(206, 246, 255, 0.2);
    border-radius: 10px;
  }
  
  /* .point_box:hover {
    box-shadow: 1px 1px 20px 10px rgba(135, 209, 255, 0.785);
    transition: all 0.4s ease-in-out;
  } */
  
  .point_box_d1 {
    padding: 8px;
    border-radius: 10px;
    background: #ffe2e1;
    width: fit-content;
    margin-right: 10px;
    /* font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320)); */
  }
  .point_box_v1 {
    width: fit-content;
    flex-direction: column;
    font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320));
  }
  .point_title {
    font-weight: 100;
    color: rgba(80, 80, 80, 0.884);
    /* font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320)); */
  }
  .point_desc {
    font-weight: 100;
    /* font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320)); */
  }
  .point_box_d2 {
    padding: 8px;
    border-radius: 10px;
    background: #ffe2e1;
    width: fit-content;
    margin-bottom: 10px;
    font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320));
  }
  .point_box_d3 {
    padding: 8px;
    border-radius: 10px;
    background: #ffe2e1;
    width: fit-content;
    margin-bottom: 10px;
    font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320));
  }
  .point_box_d4 {
    padding: 8px;
    border-radius: 10px;
    width: fit-content;
    margin-bottom: 10px;
    background: #ffe2e1;
    font-size: calc(10px + (7-25) * (100vw - 320px) / (1920-320));
  }
  
  @media only screen and (max-width: 651px) {
    .point_de_d2 {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
    }
    .point_box {
      background: white;
      margin: 2% 2%;
      padding: 15px 25px;
      flex-direction: row;
      justify-content: center;
      box-shadow: 10px 10px 10px 10px rgba(206, 246, 255, 0.2);
      border-radius: 10px;
    }
  }
  