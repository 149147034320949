.dash_host_main {
    background: #edf1f7;
    padding: 0%;
    margin: 0%;
    padding-bottom: 80px;
}

.tabList_host {
    background: #f1f5fc;
    box-shadow: rgba(46, 46, 46, 0.041) 1px 1px 3px 2px;
    border-radius: 20px;
    border: solid 1px white;
}
.dash_host_name {
    padding: 140px 9% 70px 9%;
}
.Tabs_host {
    padding: 0px 8%;
}

/* =================================================== fixed ============================ */

.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border: solid white 1px;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
}

.react-tabs__tab {
    display: inline-block;
    background: #f1f5fc;
    position: relative;
    list-style: none;
    padding: 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border: none;
    border-radius: 20px;
}

.react-tabs__tab--selected {
    background: #f1f5fc;
    color: rgb(255, 93, 93);
    border-radius: 5px 5px 0 0;
    transition: all 0.5s ease-in-out;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    background: rgb(24, 156, 244);
    transition: all 0.5s ease-in-out;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
    transition: all 0.5s ease-in-out;
}
