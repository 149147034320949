* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.header {
    width: 100%;
    background: white;
    color: rgba(80, 80, 80, 0.884);
    height: 80px;
    display: flex;
    align-items: center;
}

.hamburgerMenu {
    font-size: 16px;
    list-style-type: none;
}

.c-p-nav {
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
    font-size: 13px;
}

.loginLi {
    margin-right: 70px;
    margin-bottom: 35px;
    color: rgba(80, 80, 80, 0.884);
}

.whatLi {
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
    margin-top: 16px;
    text-decoration: none;
    margin-left: 15px;
}

.login_menu {
    color: rgba(80, 80, 80, 0.884);
    text-decoration: none;
}

.login_menu:hover {
    color: rgba(230, 4, 4, 0.521);
    border-radius: 10px;
    text-shadow: 2px 5px 10px #2c92d649;
}

.whatLiAnk {
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
}

.hd_d1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.menuD1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.host_d1 {
    padding: 0px 40px 0px 0px;
}

.nav_call {
    padding: 0px 40px 0px 0px;
}

.auth_button {
    margin-right: 15px;
    cursor: pointer;
    text-align: center;
}

.nav_call_A {
    padding: 2px 0px;
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
    font-size: calc(10px + (16-20) * (100vw - 320px) / (1920-320));
}

.host_btn1 {
    background-image: linear-gradient(to right,
            #ff745d 0%,
            #e26082 51%,
            #af5e96 100%);

    padding: 15px 13px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    font-size: 13px;
    border-radius: 10px;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    align-self: center;
}

.host_btn1:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.nav_menu_list {
    padding: 0px 50px 0px 0px;
}

.Link {
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
    font-family: "Candara";
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    font-family: "arial", serif;
}

.nav-links {
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
    padding: 0.7rem 0.4rem;
    white-space: nowrap;
    font-size: 16px;
}

.nav-links:hover {
    color: rgba(230, 4, 4, 0.521);
    border-radius: 10px;
    text-shadow: 2px 5px 10px #2c92d649;
}

.nb_logo {
    max-width: 120px;
    min-width: 20px;
    margin-left: 80px;
    align-self: center;
}

.fa_icon {
    font-size: 1.4em;
    color: rgb(119, 119, 119);
    align-self: center;
    margin-left: 18px;
}

.fa_icon2 {
    font-size: 1.4em;
    color: rgb(119, 119, 119);
    align-self: center;
    margin-left: 18px;
}

body {
    font: 100% arial, verdana, tahoma, sans-serif;
    color: #888;
    background-color: #fff;
    font-family: "arial", serif;
}

header {
    background: rgb(237, 241, 247);
    padding: 15px 0px;
    overflow: hidden;
    position: fixed;
    width: 100%;
    top: 0px;
    box-shadow: rgba(46, 46, 46, 0.041) 1px 1px 3px 2px;
    font-family: "arial", serif;
}

header h1 {
    vertical-align: middle;
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: "arial", serif;
}

main {
    padding: 30px;
}

main p {
    font-size: 0.9em;
    line-height: 1.2em;
    margin-bottom: 20px;
}

#menu {
    position: fixed;
    display: none;
}

/* ==========================logi0n =========================== */

.userLogo {
    color: #ff745d;
}

.user_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(247, 247, 247);
    width: 40px;
    height: 41px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    border: dashed 1px #ff745d;
}

/* ======================================= kebab menu ========================================== */

.km_d1_none {
    position: fixed;
    right: 2.5%;
    top: 72px;
    background: #eef3f9;
    border-radius: 10px;
    border: solid 0.1px rgb(255, 255, 255);
    box-shadow: rgba(46, 46, 46, 0.041) 1px 1px 3px 2px;
    display: none;
}

.km_d1 {
    position: fixed;
    right: 2.5%;
    top: 72px;
    background: #eef3f9;
    border-radius: 10px;
    border: solid 0.1px rgb(255, 255, 255);
    box-shadow: rgba(46, 46, 46, 0.041) 1px 1px 3px 2px;
    display: flex;
}

.km_d2 {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.km_p1 {
    color: #ff745d;
    font-size: 13px;
    text-shadow: 1px 1px 10px rgb(255, 255, 255);
    margin-bottom: 5px;
}

.km_d3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    color: rgb(167, 167, 167);
    font-size: 13px;
    transition: all 0.5s ease-in-out;
}

.km_a1 {
    text-decoration: none;
    color: inherit;
    margin-left: 5px;
}

.km_d3:hover {
    color: #ff745d;
}

.toggle-menu {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    position: fixed;
    z-index: 2;
    left: 25px;
    top: 22px;
}

.menu {
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 2;
    width: 220px;
    height: 100%;
    padding-top: 60px;
    background: #f0f4fb;
    box-shadow: 10px 20 1px rgba(0, 0, 0, 0.2);
    display: block;
    border: white 0.5px solid;
    transform: translate3d(0, 0, 0) translateX(0px);
    transition: all 0.4s cubic-bezier(0.16, 0.63, 0.45, 0.98);
    opacity: 0.9;
}

.menu .toggle-menu {
    z-index: 3;
}

.menuClose {
    max-height: 0;
    max-width: 0;
    overflow: hidden;
    opacity: 0;
}

.lnk-menu {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 10px;
    padding: 10px;
    color: rgba(80, 80, 80, 0.884);
    text-decoration: none;
}

.lnk-menu:hover {
    color: rgba(211, 17, 17, 0.521);
}

/*----------------------- OTP Error----------------------------- */

.otp{
    color: red;
    margin: 10px 5px;
    padding: 5px 5px;
    font-size: 13px;
}


@media only screen and (max-width: 910px) and (min-width: 541px) {
    .header {
        height: 70px;
        font-family: "arial", serif;
    }

    .nav-links {
        text-decoration: none;
        color: #4e5d78;
        padding: 0.7rem 1rem;
        white-space: nowrap;
        font-size: 14px;
        display: none;
    }

    .hd_d1 {
        display: flex;
        align-items: center;
    }

    .nb_logo {
        max-width: 80px;
        min-width: 20px;
        margin-left: 50px;
        align-self: center;
        margin-top: 10px;
    }

    .toggle-menu {
        width: 20px;
        left: 10px;
        top: 22px;
    }

    #menu {
        position: fixed;
        top: 70px;
        left: 0;
        z-index: 2;
        width: 220px;
        height: 100%;
        padding-top: 60px;
        background: #f0f4fb;
        box-shadow: 10px 20 1px rgba(0, 0, 0, 0.2);
        display: block;
        border: white 0.5px solid;
    }

    #menu .toggle-menu {
        z-index: 3;
    }

    .lnk-menu {
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 10px;
        padding: 10px;
        color: rgba(80, 80, 80, 0.884);
        text-decoration: none;
    }

    .lnk-menu:hover {
        color: #399;
    }

    #menu {
        -webkit-transform: translate3d(0, 0, 0) translateX(-220px);
        -moz-transform: translate3d(0, 0, 0) translateX(-220px);
        -ms-transform: translate3d(0, 0, 0) translateX(-220px);
        transform: translate3d(0, 0, 0) translateX(-220px);
        -webkit-transition: all 0.5s cubic-bezier(0.16, 0.63, 0.45, 0.98);
        -moz-transition: all 0.5s cubic-bezier(0.16, 0.63, 0.45, 0.98);
        -ms-transition: all 0.5s cubic-bezier(0.16, 0.63, 0.45, 0.98);
        transition: all 0.5s cubic-bezier(0.16, 0.63, 0.45, 0.98);
    }

    #menu:target {
        -webkit-transform: translate3d(0, 0, 0) translateX(0);
        -moz-transform: translate3d(0, 0, 0) translateX(0);
        -ms-transform: translate3d(0, 0, 0) translateX(0);
        transform: translate3d(0, 0, 0) translateX(0);
    }
}

@media only screen and (max-width: 540px) {
    .header {
        height: 70px;
    }

    .nav-links {
        text-decoration: none;
        color: rgba(80, 80, 80, 0.884);
        padding: 0.7rem 1rem;
        white-space: nowrap;
        font-size: 14px;
        display: none;
    }

    .hd_d1 {
        display: flex;
        align-items: center;
    }

    .nb_logo {
        max-width: 80px;
        min-width: 20px;
        margin-left: 43px;
        align-self: center;
        margin-top: 10px;
    }

    .fa_icon {
        font-size: 1em;
        margin-left: 0px;
    }

    .fa_icon2 {
        font-size: 1em;
        margin-left: 0px;
    }

    .toggle-menu {
        width: 20px;
        left: 10px;
        top: 22px;
    }

    #menu {
        position: fixed;
        top: 70px;
        left: 0;
        z-index: 2;
        width: 220px;
        height: 100%;
        padding-top: 60px;
        background: #f0f4fb;
        box-shadow: 10px 20 1px rgba(0, 0, 0, 0.2);
        display: block;
        border: white 0.5px solid;
    }

    #menu .toggle-menu {
        z-index: 3;
    }

    .lnk-menu {
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 10px;
        padding: 10px;
        color: rgba(80, 80, 80, 0.884);
        text-decoration: none;
    }

    .lnk-menu:hover {
        color: #399;
    }

    #menu {
        -webkit-transform: translate3d(0, 0, 0) translateX(-220px);
        -moz-transform: translate3d(0, 0, 0) translateX(-220px);
        -ms-transform: translate3d(0, 0, 0) translateX(-220px);
        transform: translate3d(0, 0, 0) translateX(-220px);
        -webkit-transition: all 0.5s cubic-bezier(0.16, 0.63, 0.45, 0.98);
        -moz-transition: all 0.5s cubic-bezier(0.16, 0.63, 0.45, 0.98);
        -ms-transition: all 0.5s cubic-bezier(0.16, 0.63, 0.45, 0.98);
        transition: all 0.5s cubic-bezier(0.16, 0.63, 0.45, 0.98);
    }

    #menu:target {
        -webkit-transform: translate3d(0, 0, 0) translateX(0);
        -moz-transform: translate3d(0, 0, 0) translateX(0);
        -ms-transform: translate3d(0, 0, 0) translateX(0);
        transform: translate3d(0, 0, 0) translateX(0);
    }

    .nav_call {
        padding: 0px 10px 0px 0px;
        display: none;
    }

    .host_d1 {
        padding: 0px 7px 0px 0px;
    }

    .host_btn1 {
        background-image: linear-gradient(to right,
                #ff745d 0%,
                #e26082 51%,
                #af5e96 100%);
        padding: 8px 12px;
        text-align: center;
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        font-size: 10px;
        border-radius: 10px;
        text-transform: uppercase;
        cursor: pointer;
        border: none;
        align-self: center;
    }

    .host_btn1:hover {
        background-position: right center;
        /* change the direction of the change here */
        color: #fff;
        text-decoration: none;
    }

    .auth_button {
        font-size: 14px;
    }
}