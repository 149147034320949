.pro_container{
    margin-top: 120px;
    margin-inline:10% ;
    color: rgba(80, 80, 80, 0.884);
}
.p_plans{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.p_pro{
    display: flex;
    margin-top: 10px;
}
@media only screen and (max-width: 480px) {
    .pro_container{
        margin-top: 120px;
        margin-inline:5% ;
        color: rgba(80, 80, 80, 0.884);
    }
    .p_plans{
        flex-direction: column;
    }
    .p_pro{
        flex-direction: column;
    }
}