.ih_main {
    padding: 70px 0px 0px 0px;
    margin: 0%;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.ih_background {
    background-image: url("../../../../R/Images/bg.svg"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}
.userInfo {
    z-index: 10;
    position: fixed;
    color: rgba(241, 45, 45, 0.26);
    top: 85px;
    opacity: 0.5;
    right: 2%;
}

.ih_d1 {
    padding: 10px 0 0 0;
    display: flex;
    height: 500px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.ih_d2 {
    display: flex;
}

.ih_d3 {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-left: 7%;
}
.ih_d3_lottie {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    width: 47%;
}

.ih_h1 {
    color: rgba(80, 80, 80, 0.884);
    font-weight: lighter;
    margin: 0px 0px 10px 0px;
    font-size: 60px;
}
.introTitleRed {
    color: rgba(255, 0, 0, 0.658);
}

.ih_p1 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 16px;
}
.ih_p2 {
    font-size: 14px;
    align-self: center;
    padding: 2% 0px 0 40%;
}
@media only screen and (max-width: 1400px) and (min-width: 1201px) {
    .ih_h1 {
        font-size: 50px;
    }
    .ih_d3_lottie {
        width: 60%;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 1025px) {
    .ih_main {
        padding: 70px 0px 0px 0px;
        margin: 0%;
        box-sizing: border-box;
    }
    .ih_background {
        background-image: url("../../../../R/Images/bg.svg"); /* The image used */
        background-color: #cccccc; /* Used if the image is unavailable */
        /* You must set a specified height */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }
    .ih_d1 {
        padding: 10px 0 0 0;
        display: flex;
        height: 500px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .ih_d2 {
        display: flex;
    }
    .ih_d3 {
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0 0px 0 90px;
    }
    .ih_h1 {
        color: rgba(80, 80, 80, 0.884);
        text-transform: uppercase;
        margin: 0px 0px 10px 0px;
        font-size: 50px;
    }
    .ih_d3_lottie {
        justify-content: center;
        display: flex;
        flex-direction: column;
        width: 70%;
        align-items: center;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 850px) {
    .ih_d1 {
        padding: 10px 0 0 0;
        display: flex;
        height: 412px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .ih_d3 {
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0 0px 0 40px;
    }
    .ih_d3_lottie {
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-left: 5%;
        width: 70%;
        align-items: center;
    }
    .ih_img1 {
        max-width: 400px;
        min-width: 200px;
        margin: 174px 0px 0px 0px;
    }
    .ih_h1 {
        color: rgba(80, 80, 80, 0.884);
        text-transform: uppercase;
        margin: 0px 0px 10px 0px;
        font-size: 40px;
    }
}
@media only screen and (max-width: 850px) and (min-width: 481px) {
    .ih_d1 {
        padding: 300px 0 100px 0;
        display: flex;
        height: 412px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .ih_d2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
        align-items: center;
    }

    .ih_d3 {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0px 0 0px;
    }
    .ih_d3_lottie {
        justify-content: center;
        display: flex;
        flex-direction: column;
        width: 80%;
        text-align: center;
        align-items: center;
    }
    .ih_img1 {
        max-width: 400px;
        min-width: 200px;
        margin: 50px 0px 0px 0px;
        align-self: center;
    }
    .ih_h1 {
        margin: 0px 0px 10px 0px;
        text-align: center;
        font-size: 40px;
    }

    .ih_p1 {
        text-align: center;
        width: 80%;
    }
}
@media only screen and (max-width: 480px) {
    .ih_d1 {
        padding: 320px 0 320px 0;
        display: flex;
        height: 412px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .ih_d2 {
        display: flex;
        flex-direction: column;
    }

    .ih_d3 {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0px 0 0px;
    }
    .ih_d3_lottie {
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0%;
        width: 100%;
        align-items: center;
    }
    .ih_img1 {
        max-width: 80%;
        min-width: 200px;
        margin: 50px 0px 0px 0px;
        align-self: center;
    }
    .ih_h1 {
        margin: 0px 0px 10px 0px;
        text-align: center;
        width: 80%;
        font-size: 35px;
    }
    .ih_p1 {
        text-align: center;
        width: 80%;
    }
}
