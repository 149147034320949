.Tcontainer{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 120px;
    font-size: 16px;
    color: rgba(80, 80, 80, 0.884);
}

.Theader{
    display: flex;
    margin-bottom: 15px;
    color: rgba(80, 80, 80, 0.884);
    justify-content: center;
}
.header2{
    color: rgba(80, 80, 80, 0.884); 
    font-weight: 600;
    margin-bottom: 7px;
}

