.CarSearchConataner {
    margin-top: 80px;
    margin-inline: 0%;
}
.sHeadCar {
    margin-inline: 10%;
}
.content-hidden{
    visibility: hidden;
    max-height: 0;
}


@media only screen and (max-width: 480px) {
  
    .CarSearchConataner {
        margin-inline: 0%;
        margin-top: 10px;
    }
    .sHeadCar {
        margin-inline: 5%;
    }
}
