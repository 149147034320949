.about_container {
  margin-top: 7%;
  margin-right: 3%;
  margin-left: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about_container h1{
  color: rgba(80, 80, 80, 0.884);
margin-top: 30px;
text-align: center;
}
.about_logo {
  max-width: 250px;
  min-width: 80px;
  align-self: center;
  margin-top: 10px;
}
.about_text {
  color: rgba(80, 80, 80, 0.884);
  font-size: 16px;
  margin-top: 2%;
}
.aboutValues{
  padding-left: 2%;
}

