.blogDtlsContainer{
    margin-top: 120px;
    margin-inline: 15%;
    color: rgba(80, 80, 80, 0.884);
    font-family: 'Poppins' !important;
}
.blogCover{
    width: 100%;
    height: 300px;
}
.blogTitle{
 margin-block: 30px;
}
.blogName{
 margin-block: 10px;
 font-size: 16px;
}
.blogDate{
 margin-block: 5px;
 font-size: 12px;
}
.blogText{
 margin-block: 30px;
 font-size: 16px;
 font-family: 'Poppins' !important;
}

@media only screen and (max-width: 617px) {
    .blogDtlsContainer{
        margin-inline: 8%;
    }
}