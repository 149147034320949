.ad_p_h_container {
    position: relative;
    padding-top: 82px;
    display: flex;
    background-color: #f8f9fa;
    color: rgba(80, 80, 80, 0.884);
}
.ad_h_comp_3 {
    width: 300px;
}
.ad_h_comp_1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
}
.hostHead {
    margin: 30px;
    text-align: center;
}

.ad-host-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ad-host-info {
    font-size: 16px;
    margin-bottom: 10px;
}

.ad-host-image {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
}

.ad-host-not-found {
    font-size: 18px;
    text-align: center;
    color: #f00;
}
