/* login1 */

.login1-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    color: rgba(80, 80, 80, 0.884);
}

.login1-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

.login1-boxes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
}

.login1-box {
    text-decoration: none;
    color: rgba(80, 80, 80, 0.884);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 50px;
    margin-top: 20px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border-radius: 10px;
}

.line1-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 200px;
}
.signup_links {
    color: rgba(80, 80, 80, 0.884);
    text-decoration: none;
}
.signup1-box {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 0, 0, 0.603);
    width: 400px;
    height: 50px;
    box-shadow: 0px 0px 7px rgba(255, 0, 0, 0.468);
    cursor: pointer;
    border-radius: 10px;
}
.login_Icons {
    color: rgba(80, 80, 80, 0.884);
    margin-left: 10px;
}

/* login-2 */

.login2-container {
    color: rgba(80, 80, 80, 0.884);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.login2-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

.input2-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 50px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin-bottom: 20px;
}

.input2-box input {
    border-radius: 10px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 10px;
}
.proceed2-button {
    color: white;
    display: flex;
    background: rgba(255, 0, 0, 0.603);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 50px;
    border: 1px solid rgba(255, 0, 0, 0);
    cursor: pointer;
}
.proceed2-buttonShadow {
    box-shadow: 0px 0px 7px rgba(255, 0, 0, 0.468);
}
.proceed2-button.disabled {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.281);
    /* Styles for the disabled button */
    background-color: #ccc;
    border: 1px solid #ccc;
    cursor: not-allowed;
    /* Add any other styles to make it visually disabled */
}

/* login-3 */

.login3-container {
    color: rgba(80, 80, 80, 0.884);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    padding-block: 100px;
    padding: 20px;
    border-radius: 4px;
}

.login3-header {
    text-align: center;
    margin-bottom: 20px;
}

.input3-box {
    margin-bottom: 15px;
}

.input3-box label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.input3-box input[type="text"],
.input3-box input[type="date"],
.input3-box input[type="file"] {
    border: none;
    outline: none;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 400px;
    padding: 10px;
}
.input-otp-in {
    border: none;
    outline: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 400px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.radio3-inputs {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    margin-top: 5px;
}

.proceed3-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
