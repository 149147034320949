.car-rental-form-db {
    margin-top: 120px;
    margin-bottom: 50px;
    margin-inline: 10%;
    font-size: 17px;
    display: flex;
    color: #333333;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.rent_1,
.rent_2-db {
    display: flex;
    flex-direction: column;
}
.rent_1 {
    width: 45%;
}
.rent_2-db {
    width: 23%;
    margin-right: 30px;
}

.form-row {
    display: flex;
    margin-bottom: 20px;
}
.form-row-2-db {
    display: flex;
    margin-bottom: 10px;
}

.form-row,
.form-row-2-db label {
    margin-bottom: 5px;
    font-weight: bold;
}

.inputField-1 {
    position: relative;
    width: 100%;
    background-color: #f5e3e3;
    color: #333;
    display: flex;
    align-items: center;
    padding-inline: 10px;
    border-radius: 5px;
}
.inputField-2 {
    position: relative;
    width: 100%;
    background-color: #f5e3e3;
    color: #333;
    display: flex;
    align-items: center;
    padding-inline: 10px;
    border-radius: 5px;
}
.inputField-1:focus-within {
    border: 3px solid #333;
}
.inputField-2:focus-within {
    border: 3px solid #333;
}

.locIcon {
    margin-left: 10px;
    width: 25px;
    height: 30px;
}

.pickup-location:focus {
    outline: none; /* Remove the default focus outline */
    border-color: transparent;
}
.form-row-2-db input[type="datetime-local"]:focus {
    outline: none; /* Remove the default focus outline */
    border-color: transparent;
}

.pickup-location {
    width: 100%;
    height: 75px;
    margin: 0;
    padding: 15px 0px 15px 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    background-color: #f5e3e3;
    color: #333;
}
.pickup-location::placeholder {
    color: #999;
    font-size: 16px;
}
.pickup-date {
    appearance: none;
    -moz-appearance: textfield; /* Firefox support */
    border: none;
}
.form-row-2-db input[type="datetime-local"] {
    width: 100%;
    height: 75px;
    padding: 15px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    background-color: #f5e3e3;
    color: #333;
}
.form-row-2-db input[type="text"]::placeholder,
.form-row-2-db input[type="datetime-local"]::placeholder {
    color: #999;
    font-size: 16px;
}

.rent_bottom {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rent-button {
    background-color: rgba(241, 47, 47, 0.781);
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    font-size: 17px;
    padding: 15px;
    cursor: pointer;
    width: 20%;
}
.rent-button:hover {
    background-color: rgba(255, 0, 0, 0.493);
}

.rent-button:disabled {
    background-color: #a4a4a4;
    cursor: not-allowed;
}
.error {
    border: 2px solid red;
}
@media only screen and (max-width: 480px) {
    .car-rental-form-db {
        margin-inline: 5%;
    }
    .form-row {
        flex-direction: column;
    }
    .form-row-2-db {
        flex-direction: column;
    }
    .rent_1 {
        width: 100%;
        margin-bottom: 15px;
    }
    .rent_2-db {
        width: 100%;
        margin-bottom: 15px;
    }
    .rent_bottom {
        flex-direction: column;
    }
    .rent_bottom {
        margin-top: 10px;
    }
    .rent-button {
        margin-top: 10px;
        width: 100%;
    }
    .negotiated-input {
        margin-right: 0px;
        flex-direction: column;
        align-items: flex-start;
    }
}
