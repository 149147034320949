.c_book_c {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 10px;
}
.bookHead {
    margin: 30px;
    text-align: center;
}
.d_btn_book {
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
    border: none;
    margin-block: 50px;
    background: rgba(255, 0, 0, 0.521);
}
.d_btn_book_con {
    display: flex;
    justify-content: center;
}
.show_none {
    max-height: 0px;
    visibility: hidden;
}
.popup-b-i-p {
    width: 90%;
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
}
.popup_p {
    display: flex;
    justify-content: center;
}
.popup_p-c {
    width: 100%;
    align-items: center;
    text-align: center;
}

.date-filters-con {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.date-filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.date-filters button {
    background-color: rgba(228, 7, 7, 0.24);
    color: rgba(80, 80, 80, 0.884);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    width: 130px;
}

.date-filters button.active {
    color: white;
    background-color: rgba(228, 7, 7, 0.534);
}

.custom-date-range {
    display: flex;
    gap: 10px;
    align-items: center;
}

.custom-date-range label {
    font-weight: bold;
}

.custom-date-range input[type="date"] {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
}
