.recentBlogsContainer {
    margin-top: 50px;
    text-align: center;
    margin-inline: 10%;
    color: rgba(80, 80, 80, 0.884);
    margin-bottom: 120px;
}
.recentBlogCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
    row-gap: 50px;
    column-gap: 5%;
}
@media only screen and (max-width: 617px) {
    .recentBlogCards {
        grid-template-columns: repeat(1, 1fr);
    }
    .recentBlogsContainer {
        margin-left: 18%;
    }
}
