.carDtlsContainer {
    margin-block: 2%;
    margin-inline: 10%;
}
.carDtlsContainer h4 {
    margin-block: 10px;
}
.carDtlsContainer input {
    margin-bottom: 5px;
    padding: 5px;
}
.carDtlsbtns {
    display: flex;
    flex-direction: column;
}
.carDtlsbtns button {
    margin-bottom: 10px;
    padding: 5px;
}

.popup_b button {
    font-family: "Poppins", sans-serif;
    background-image: linear-gradient(
        to right,
        #ff745d 0%,
        #e26082 51%,
        #af5e96 100%
    );
    margin: 10px;
    padding: 15px 13px 12.5px 13px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    font-size: 13px;
    border-radius: 10px;
    display: block;
    cursor: pointer;
    border: none;
    width: 100px;
}
.popup_b button:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
.select-car-ad {
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
}

.img-car-ad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.add-acc-image {
    width: 200px;
}
.car-dtls-btns-car-red {
    background-color: #f84848cb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.car-dtls-btns-car-red:hover {
    background-color: #a10c0ccb;
}
.car-dtls-btns-car {
    background-color: #34f544cb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.car-dtls-btns-car:hover {
    background-color: #0a7513cb;
}
