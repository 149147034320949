/* classes are copied and also used from featured cars component */
.s2-conatainer {
    margin-top: 60px;
    margin-bottom: 30px;
    align-items: center;
    border-bottom: 1px solid rgba(105, 100, 100, 0.411);
}
.s2-conatainer_1{
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sc_h2{
    width: 200px;
    margin-bottom: 15px;
}
.detailed_info_container{
    background-color: #f8f9fa;
    padding: 3%;
    margin-bottom: 35px;
}
.detailed_info_container2{
    display: flex;
    justify-content: space-between;
}
.basic_p_container{
    background-color: #f8f9fa;
    padding: 3%;
    margin-bottom: 20px;
}
.basic_p_in{
    color: red;
}
.basic_p_h1,.basic_p_h2{
    margin-bottom: 20px;
}
.basic_p_icon1{
    color: green;
    margin-right: 5px;
}
.basic_p_icon2{
    margin-right: 5px;
}
.buttonMore{
    margin-top: 20px;
    color:    #e26082 ;
    cursor: pointer;
}
.content-close {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    
  }
.content-open {
    opacity: 1;
    padding: 1rem;
    height: auto;
    max-height: 9999px;
  }
.sc_d9 {
    font: 16px;
    margin-bottom: 5px;
}
.sc_d10 {
    font: 16px;
}
.s-c-last {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.fc_btn1 {
    background-image: linear-gradient(
        to right,
        #ff745d 0%,
        #e26082 51%,
        #af5e96 100%
    );
    margin: 10px;
    text-decoration: none;
    padding: 15px 13px 12.5px 13px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    font-size: 13px;
    border-radius: 10px;
    display: block;
    cursor: pointer;
    border: none;
}

.fc_btn1:hover {
    color: #fff;
}
.fc_span2 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 20px;
}
.sc_img_d1 {
    width: 400px;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
}

.sc_img1 {
    width: 400px;
    border-radius: 10px;
    align-self: center;
    transition: 0.5s all ease-in-out;
}
.sc_img_d1:hover .fc_img1 {
    transform: scale(1.2);
}
.sc_span1 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 16px;
}
.sc_d4 {
    flex-direction: row;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    margin: 10px 5px;
    padding-left: 5%;
    color: rgba(80, 80, 80, 0.884);
}
.sc_d5 {
    border: 1px solid #ff755d79;
    border-radius: 10
    px;
    padding-block: 2px;
    text-align: center;
    margin-right: 80px;
    margin-bottom: 15px;
}
.buttonSold {
    background-color: #8d8b8b;
    margin: 10px;
    text-decoration: none;
    padding: 15px 13px 12.5px 13px;
    text-align: center;
    text-transform: uppercase;
    background-size: 200% auto;
    color: white;
    font-size: 13px;
    border-radius: 10px;
    display: block;
    border: none;
}
.sCarBottomHead{
    margin-bottom: 25px;
}
@media only screen and (max-width: 480px) {
    .s2-conatainer {
        flex-direction: column;
    }
    .sc_img_d1 {
        width: 100%;
    }
    .s2-conatainer_1{
       flex-direction: column;
       margin-bottom: 10px;
    }
    .sc_d4 {
        width: 100%;
        padding-left: 0%;
        padding-inline: 20%;
    }
    .s-c-last {
        width: 100%;
        padding-left: 0%;
        padding-inline: 20%;
    }
    .detailed_info_container2{
        flex-direction: column;
    }
}
