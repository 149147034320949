.Ch_main {
    padding: 70px 0px 0px 0px;
    margin: 0%;
    box-sizing: border-box;
  }
  
  .Ch_background {
    background-image: url("../../R/Images/bg.svg"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  
  .Ch_d1 {
    padding: 10px 0 0 0;
    display: flex;
    height: 500px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .Ch_d2 {
    display: flex;
  }
  
  .Ch_d3 {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 0px 0 120px;
  }
  
  .Ch_img1 {
    max-width: 550px;
    min-width: 200px;
    margin: 174px 0px 0px 0px;
  }
  
  .Ch_h1 {
    color: rgba(80, 80, 80, 0.884);

    text-transform: uppercase;
    margin: 0px 0px 10px 0px;
  }
  
  .Ch_p1 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 15px;
  }
  .Ch_p2 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 18px;
    align-self: center;
    padding: 2% 0px 0 40%;
  }
  
  @media only screen and (max-width: 600px) {
    
    .Ch_main {
      margin: 0%;
      margin-bottom: 150px;
      box-sizing: border-box;
    }
    .Ch_d2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12%;
      margin-top: 40px;
    }
    .Ch_d1 {
      padding: 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .Ch_d3 {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  .Ch_img1 {
    max-width: 550px;
    min-width: 200px;
    margin: 0
  }
  
  .Ch_h1 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 24px;
    text-transform: uppercase;
    margin: 0;
    margin-top: 100px;
  }
  
  .Ch_p1 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 15px;
    margin-block: 15px 
  }
  .Ch_p2 {
    color: rgba(80, 80, 80, 0.884);
    font-size: 18px;
    align-self: center;
    padding: 0
  }
  }
  