.input2-box-o {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 50px;
    margin-bottom: 20px;
}
.input2-box-o2 {
    display: flex;
    justify-content: space-between;
    outline: none;
    border: none;
    align-items: center;
    height: 80px;
    width: 400px;
    padding: 10px;
    border-radius: 4px;
}
.input2-box-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 50px;
    margin-bottom: 20px;
}
.otp-container {
    height: 50px;
    padding: 5px;
}
.otp-inputs {
    height: 20px;
    width: 50px;
}
.otp-inputs2 {
    height: 20px;
    width: 30px;
    visibility: hidden;
}
.proceed2-button-skp {
    color: white;
    display: flex;
    background: rgba(76, 158, 252, 0.603);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 50px;
    border: 1px solid rgba(255, 0, 0, 0);
    cursor: pointer;
    box-shadow: 0px 0px 7px rgba(122, 185, 236, 0.468);
}
.divider {
    margin-block: 20px;
    text-align: center;
}
