.offer_Con {
    color: rgba(80, 80, 80, 0.884);
}
.offerHead {
    margin: 30px;
    text-align: center;
}

.popup-screen-offer {
    color: rgba(80, 80, 80, 0.884);
    position: fixed;
    z-index: 3;
    top: 0;
    padding-top: 250px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}

.offer_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 20px;
    margin-bottom: 30px;
}
.offer_Card {
    width: 320px;
    background-color: white;
    padding: 10px;
}
.off_img {
    height: 200px;
    width: 300px;
}
.off_dec {
    margin-block: 10px;
}
.off_btn {
    display: flex;
    justify-content: space-between;
}
.off_btn_1 {
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    border: none;
    background-image: linear-gradient(
        to right,
        #ff745d 0%,
        #e26082 51%,
        #af5e96 100%
    );
    color: white;
}
.off_btn_2 {
    font-size: 16px;
    cursor: pointer;
    padding: 15px;
    border: none;
    background-color: rgba(0, 255, 0, 0.616);
    color: white;
}
.popup-2 input {
    width: 500px;
    height: 50px;
}
.plusTerm {
    margin-inline: 5px;
    cursor: pointer;
}
