.ex_bot{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ex_bot p{
    text-align: center;
}
.ex_bot button{
    width: 200px;
    background: white;
    font-size: 34px;
    height: 50px;
    border-radius: 5px;
   cursor: pointer;
}

@media only screen and (max-width: 480px) {
    .b_contain{
        margin-right: 0px;
        margin-bottom: 10px;
    }
}