.b_contain{
    color: rgba(80, 80, 80, 0.884);
    padding: 15px;
    border: 1px solid rgba(128, 128, 128, 0.411);
    border-radius: 10px;
    margin-right: 20px;
}
.b_top{
    border-bottom: 1px solid rgba(128, 128, 128, 0.384);
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.b_mid{
font-size: 13px;
}
.b_mid p{
margin-bottom: 5px;
}
.mid_disabled_p{
    color: rgba(80, 80, 80, 0.37);
}
.b_bot{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.b_bot button{
    padding-inline: 20px;
    padding-block: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgba(231, 47, 47, 0.527);
    color: white;
}
.b_icon{
    margin-inline: 10px;
}
@media only screen and (max-width: 480px) {
    .b_contain{
        margin-right: 0px;
        margin-bottom: 10px;
    }
}