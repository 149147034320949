.review_container{
  width: 80%;
  margin-inline: auto;
  margin-top: 50px;
  font-family: 'Poppins', sans-serif;
}
.review_header{
    margin-bottom: 10px;
    text-align: center;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GIcon{
    width: 240px;
}
.starIconsContainerTop{
    display: flex;
    margin-left: 12px;
}

.nameAndStar{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.nameInitialContainer{
display: flex;
align-items: center;
}
.nameInitial{
    margin-left: 10px;
}
.contentStyle{
    padding-block: 4%;
    padding-inline: 6%;
    color: rgba(80, 80, 80, 0.884);
    height: 350px;
    border-radius: 10px;
    border: .5px solid rgba(80, 80, 80, 0.404);
}
.rateContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
}
.rateText{
    font-size: 20px;
    margin-left: 15px;
}
.starIconsContainer{
    display: flex;
}
.starIcons{
    width: 20px;
    margin-inline: 2px;
}
.reviewQT{
    width: 50px;
}

.text_container{
    margin-top: 30px;
    text-align: center;
}
.review_text{
    font-size: 16px;
}

  
  /* Custom Coloring */


  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-prev:focus,
  .ant-carousel .slick-next:focus {
    font-size: inherit;
    color: gray !important;
  }
  
  .ant-carousel .slick-prev::before {
    color: gray !important;
  }
  
  .slick-arrow.slick-prev {
    font-size: 20px !important;
  }
  
  .ant-carousel .slick-next::before {
    color: gray !important;
  }
  
  .slick-arrow.slick-next {
    font-size: 20px !important;
  }
  
  .ant-carousel .slick-dots li button {
    background: rgba(224, 12, 12, 0.384);
    opacity: 0.4;
  }
  
  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: red;
  }

 

@media only screen and (max-width: 600px)  {
    .contentStyle{
        padding: 5%;
        height: 400px; 
    }
    .review_text{
        font-size: 16px;
        flex-flow: wrap;
    }
  }
